import { Alert, AlertDescription, AlertIcon } from "@kvib/react"
import { useFormContext } from "react-hook-form"
import { EgenregistreringSchema } from "../types/schema"

export const InvalidInputAlert = ({ felt }: { felt: keyof EgenregistreringSchema }) => {
  const formContext = useFormContext<EgenregistreringSchema>()

  const isInvalidInput =
    formContext.getFieldState(felt).error?.type !== "custom" && formContext.formState.errors[felt]

  return isInvalidInput ? (
    <Alert mt="12px" status="error" variant="top-accent">
      <AlertIcon />
      <AlertDescription>{formContext.getFieldState(felt).error?.message}</AlertDescription>
    </Alert>
  ) : null
}
