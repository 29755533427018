import { useFormContext } from "react-hook-form"
import { formaterMedKommaOgOgKunStoreBokstaver } from "../../../utils/formateringUtils"
import { EgenregistreringBygning } from "./types/Egenregistrering"
import { Avlopstype, Energikildetype, Oppvarmingstype, Vannforsyningstype } from "./types/Enums"
import { EgenregistreringSchema, Felter, FelterLabels, IkonForFelt } from "./types/schema"

export const getModalLabel = (field: keyof EgenregistreringSchema): FelterLabels => {
  switch (field) {
    case "byggeaar":
      return "byggeår"
    case "bruksareal":
      return "totalt bruksareal"
    case "energikilder":
      return "energikilder"
    case "oppvarminger":
      return "oppvarmingstyper"
    case "vannforsyning":
      return "vannforsyning"
    case "avlop":
      return "avløp"
    default:
      return ""
  }
}

export const visFeltverdi = (key: Felter, egenregistreringForBygning: EgenregistreringBygning) => {
  switch (key) {
    case "byggeaar":
      return egenregistreringForBygning.byggeaar?.egenregistrert?.data ?? ikkeRegistrertVerdi
    case "bruksareal":
      return egenregistreringForBygning.bruksareal?.egenregistrert?.data
        ? `${egenregistreringForBygning.bruksareal.egenregistrert.data} m²`
        : ikkeRegistrertVerdi
    case "energikilder": {
      const energikilder =
        egenregistreringForBygning.bruksenheter[0].energikilder?.egenregistrert || []
      return energikilder.length > 0
        ? formaterMedKommaOgOgKunStoreBokstaver(
            energikilder.map(energikilde => Energikildetype[energikilde.data]),
          )
        : ikkeRegistrertVerdi
    }
    case "oppvarminger": {
      const oppvarmingstyper =
        egenregistreringForBygning.bruksenheter[0].oppvarminger?.egenregistrert || []
      return oppvarmingstyper.length > 0
        ? formaterMedKommaOgOgKunStoreBokstaver(
            oppvarmingstyper.map(oppvarming => Oppvarmingstype[oppvarming.data]),
          )
        : ikkeRegistrertVerdi
    }
    case "vannforsyning":
      return egenregistreringForBygning.vannforsyning?.egenregistrert?.data
        ? Vannforsyningstype[egenregistreringForBygning.vannforsyning.egenregistrert.data]
        : ikkeRegistrertVerdi
    case "avlop":
      return egenregistreringForBygning.avlop?.egenregistrert?.data
        ? Avlopstype[egenregistreringForBygning.avlop.egenregistrert.data]
        : ikkeRegistrertVerdi
    default:
      return ikkeRegistrertVerdi
  }
}

export const ikkeRegistrertVerdi = "---"

export const felter: Array<{ key: Felter; label: FelterLabels; icon: IkonForFelt }> = [
  { key: "byggeaar", label: getModalLabel("byggeaar"), icon: "calendar_today" },
  { key: "bruksareal", label: getModalLabel("bruksareal"), icon: "square_foot" },
  { key: "energikilder", label: getModalLabel("energikilder"), icon: "lightbulb" },
  { key: "oppvarminger", label: getModalLabel("oppvarminger"), icon: "mode_heat" },
  { key: "vannforsyning", label: getModalLabel("vannforsyning"), icon: "water_drop" },
  { key: "avlop", label: getModalLabel("avlop"), icon: "valve" },
]

export const useSkalViseTomtSkjemaFeilmelding = () => {
  const formContext = useFormContext<EgenregistreringSchema>()

  return (
    formContext.formState.isSubmitted &&
    !formContext.formState.isSubmitSuccessful &&
    !formContext.formState.isValid &&
    Object.values(formContext.formState.errors).every(err => err.type === "custom")
  )
}

export const harNøyaktigEnBruksenhetIBygning = (bruksenhetIds: string[]): boolean => {
  return bruksenhetIds.length === 1
}
