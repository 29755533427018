const keys = {
  "nb-NO": {
    egenregistrering_sidetittel: "Egenregistrerte opplysninger",
    hjelpetekst_avløp:
      "Avløpskoden beskriver systemet for håndtering av brukt vann fra bygningen. Det gjelder både hvordan vann fra vask og dusj, og vann fra toalett, blir håndtert.",
    hjelpetekst_bruksareal:
      "Du må registrere det totale bruksarealet for hver etasje i bygningen. Bruksareal inkluderer alt areal innenfor ytterveggene. Dersom du har innglasset balkong inngår dette i bruksarealet.",
    hjelpetekst_byggeår:
      "Byggeåret er det året bygningen var ferdigstilt og kunne tas i bruk første gang. Rehabilitering, oppussing og påbygning i ettertid endrer ikke byggeåret.",
    hjelpetekst_energikilder:
      " Energikildene beskriver de faste installasjonene for energiforsyning i en bygning. Bygningen kan ha flere energikilder.",
    hjelpetekst_oppvarmingstyper:
      "Oppvarmingstypene beskriver hovedmetodene som brukes for å varme opp bygningen. Bygningen kan ha flere oppvarmingstyper.",
    hjelpetekst_vannforsyning:
      " Vannforsyning beskriver om og hvordan bygningen får tilgang til rent vann. Dersom du henter vann fra bekk eller brønn uten tilkobling til bygningen benyttes 'annen privat men ikke innlagt vann'.",
    tekst_registrer_verdi_knapp: "registrer",
    tekst_endre_verdi_knapp: "endre",
    tekst_fjern_verdi_knapp: "fjern",
  },
  "nn-NO": {
    hjelpetekst_avløp: "",
    hjelpetekst_bruksareal: "",
    hjelpetekst_byggeår: "",
    hjelpetekst_energikilder: "",
    hjelpetekst_oppvarmingstyper: "",
  },
} as const

export const egenregistrering = {
  "nb-NO": keys["nb-NO"],
  "nn-NO": keys["nn-NO"],
}
