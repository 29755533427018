import {
  Alert,
  AlertDescription,
  AlertIcon,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@kvib/react"
import { useFormContext } from "react-hook-form"
import { EgenregistreringSchema, Felter, FelterLabels, IkonForFelt } from "../types/schema"
import { useSkalViseTomtSkjemaFeilmelding } from "../utils"
import { IkonOgFeltLabel } from "./IkonOgFeltLabel"
import RegistrerAvlop from "./RegistrerAvløp"
import RegistrerBruksareal from "./RegistrerBruksareal"
import RegistrerByggeår from "./RegistrerByggeår"
import RegistrerEnergikilder from "./RegistrerEnergikilder"
import RegistrerOppvarmingstyper from "./RegistrerOppvarmingstyper"
import RegistrerVannforsyning from "./RegistrerVannforsyning"

interface EgenregistreringModalProps {
  felt: {
    key: Felter
    label: FelterLabels
    icon: IkonForFelt
  }
  isOpen: boolean
  onClose: () => void
  onSubmit: (data: EgenregistreringSchema) => void
  isLoading: boolean
}

const EgenregistreringModal = ({
  felt,
  isOpen,
  onClose,
  onSubmit,
  isLoading,
}: EgenregistreringModalProps) => {
  const formContext = useFormContext<EgenregistreringSchema>()

  const handleSubmit = () => {
    formContext.handleSubmit(onSubmit)()
  }

  const getFieldContent = (field: keyof EgenregistreringSchema) => {
    switch (field) {
      case "byggeaar":
        return <RegistrerByggeår />
      case "bruksareal":
        return <RegistrerBruksareal />
      case "energikilder":
        return <RegistrerEnergikilder />
      case "oppvarminger":
        return <RegistrerOppvarmingstyper />
      case "vannforsyning":
        return <RegistrerVannforsyning />
      case "avlop":
        return <RegistrerAvlop />
      default:
        return null
    }
  }

  const skalViseTomtSkjemaFeilmelding = useSkalViseTomtSkjemaFeilmelding()

  return (
    <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false} size="xl">
      <ModalOverlay />
      <ModalContent gap={2}>
        <ModalHeader>
          <IkonOgFeltLabel felt={felt} />
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {getFieldContent(felt.key)}
          {skalViseTomtSkjemaFeilmelding && (
            <Alert mt="24px" status="error" variant="top-accent">
              <AlertIcon />
              <AlertDescription>Vennligst fyll inn minst én gyldig verdi</AlertDescription>
            </Alert>
          )}
        </ModalBody>
        <ModalFooter>
          <Button variant="secondary" colorScheme="blue" mr={3} onClick={onClose}>
            Avbryt
          </Button>
          <Button onClick={handleSubmit} isLoading={isLoading}>
            Send inn
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default EgenregistreringModal
