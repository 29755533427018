import { Stack } from "@kvib/react"
import { useTranslation } from "react-i18next"
import ErrorHandler from "../../../../core/error-handling/ErrorHandler"
import { BygningsstatusHistorikkResponse } from "../../../../shared-api/types/ApiResponseTypes"
import { useKodelister } from "../../../kodelister/useKodelister"
import { hentUtfraKodeliste } from "../../../kodelister/utils"
import { Bygning, Bygningsnøkkelinfo } from "../types/Bygning"
import { bygningstyperMapper } from "../utils/bygningstypeUtil"
import { gyldigeBygningsstatuser } from "../utils/gyldigeBygningsstatuser"
import Bygningsstatus from "./Bygningsstatus"
import { FelterIBygningskort } from "./FelterIBygningskort"

const OmBygningen = ({ bygning }: { bygning: Bygning }) => {
  const { t } = useTranslation(["bygningskort", "nøkkelbegreper"])
  const { data: kodelister, isError, isLoading, isSuccess, error } = useKodelister()

  if (isError) return <ErrorHandler apiError={error} />

  if (isLoading || !isSuccess) return null

  const identifikatorForBygning: Bygningsnøkkelinfo[] = [
    {
      title: t("bygningstype_felt"),
      id: "bygningstype",
      arialabel: t("hva_betyr_type_arialabel", { ns: "nøkkelbegreper", type: "bygningstype" }),
      forklaringAvAttributt: t("bygningstype_forklaring"),
      evaluate: (bygning: Bygning) => bygningstyperMapper[bygning.bygningstype],
    },
    {
      title: t("bygningsnummer_felt"),
      id: "bygningsnummer",
      arialabel: t("hva_betyr_type_arialabel", { ns: "nøkkelbegreper", type: "bygningsnummer" }),
      forklaringAvAttributt: t("bygningsnummer_forklaring"),
      evaluate: (bygning: Bygning) => bygning.bygningsnummer,
    },
    {
      title: t("bygningsstatus_felt"),
      id: "bygningsstatuser",
      arialabel: t("hva_betyr_type_arialabel", { ns: "nøkkelbegreper", type: "bygningsstatus" }),
      forklaringAvAttributt: t("bygningsstatus_forklaring"),
      evaluate: (bygning: Bygning) => {
        const bygningsstatusHistorikkUtenEBStatuser = bygning.bygningsstatusHistorikk.filter(e =>
          gyldigeBygningsstatuser.includes(e.status),
        )
        const hentGjeldendeBygningsstatus = (
          bygningsstatusHistorikk: BygningsstatusHistorikkResponse[],
        ) => {
          // Historikkene kommer sortert fra matrikkelen, eldste til nyeste
          const bygningsHistorikk = bygningsstatusHistorikk.slice().reverse()

          return bygningsHistorikk.map(historikk => {
            const { status, år } = historikk
            const mappedStatus = hentUtfraKodeliste(status, kodelister.bygningsstatusKodeliste)

            return år === "" ? mappedStatus : `${år}: ${mappedStatus},`
          })
        }
        return bygningsstatusHistorikkUtenEBStatuser.length === 0
          ? t("ikke_registrert", { ns: "nøkkelbegreper" })
          : hentGjeldendeBygningsstatus(bygningsstatusHistorikkUtenEBStatuser)
      },
    },
  ]

  const hentRiktigVisningForAttributt = (attributt: Bygningsnøkkelinfo, index: number) => {
    if (attributt.id === "bygningsstatuser") {
      return <Bygningsstatus key={index} bygning={bygning} attributt={attributt} />
    } else {
      return <FelterIBygningskort key={index} bygning={bygning} attributt={attributt} />
    }
  }

  return (
    <Stack gap={3} mt="12px">
      {identifikatorForBygning.map((attributt, index) =>
        hentRiktigVisningForAttributt(attributt, index),
      )}
    </Stack>
  )
}

export default OmBygningen
