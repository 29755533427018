import { FormLabel, Radio, RadioGroup, Stack } from "@kvib/react"
import { useFormContext } from "react-hook-form"
import { EgenregistreringSchema, Felter, FelterLabels, IkonForFelt } from "../types/schema"
import { getModalLabel } from "../utils"

interface Props<T> {
  name: keyof EgenregistreringSchema
  felt: {
    key: Felter
    label: FelterLabels
    icon: IkonForFelt
  }
  options: T
}

const VannforsyningOgAvløpRadioGroup = <T extends object>({ name, options, felt }: Props<T>) => {
  const { register } = useFormContext<EgenregistreringSchema>()

  return (
    <>
      <RadioGroup>
        <FormLabel>Velg {getModalLabel(felt.key)} for bygningen</FormLabel>
        <Stack spacing={2} ml="12px">
          {Object.entries(options).map(([key, label]) => (
            <Radio key={key} value={key} {...register(name)}>
              {label}
            </Radio>
          ))}
        </Stack>
      </RadioGroup>
    </>
  )
}

export default VannforsyningOgAvløpRadioGroup
