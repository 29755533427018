import { useMemo } from "react"
import { useParams } from "react-router-dom"
import useCurrentEiendom from "./useCurrentEiendom"

const useCurrentBygning = (currentBygningId?: string) => {
  const { bygningId: paramBygningId } = useParams<{ bygningId: string }>()
  const { eiendom } = useCurrentEiendom()

  const bygningId = currentBygningId || paramBygningId

  const bygningIdNumber = useMemo(() => Number(bygningId), [bygningId])

  const currentBygning = useMemo(() => {
    return eiendom?.bygningerPåEiendom.find(bygning => Number(bygning.byggId) === bygningIdNumber)
  }, [eiendom, bygningIdNumber])

  const bruksenhetIds = useMemo(() => {
    const bruksenheterIBygning = eiendom?.bruksenheterPåEiendom.filter(
      bruksenhet => Number(bruksenhet.byggId) === bygningIdNumber,
    )
    return bruksenheterIBygning?.map(b => b.bruksenhetsId) ?? undefined
  }, [eiendom, bygningIdNumber])

  return { currentBygning, bruksenhetIds }
}

export default useCurrentBygning
