import { apiRoute } from "../../../../environments"
import { ApiError } from "../../../core/error-handling/ApiError"
import { EgenregistreringBygning } from "../types/Egenregistrering"
import { EgenregistreringSchema } from "../types/schema"
import { OpprettEgenregistreringDTO } from "./useOpprettEgenregistering"

export const egenregistreringRouter = {
  kanEgenregistrere: () => {
    const url = `${apiRoute}/kanEgenregistrere`

    return new Promise<boolean | null>((resolve, reject) => {
      fetch(url)
        .then(async response => {
          if (!response.ok) {
            resolve(null)
            return
          }
          const kanEgenregistrere: {
            isEnabled: boolean
          } = await response.json()
          resolve(kanEgenregistrere.isEnabled)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  sendEgenregistrering: async (dto: OpprettEgenregistreringDTO) => {
    const url = `${apiRoute}/egenregistrering/${dto.bygningId}`
    const { egenregistrering } = dto

    const data = {}

    const addPropertyIfDefined = (
      data: Record<string, unknown>,
      egenregistrering: EgenregistreringSchema,
      key: keyof EgenregistreringSchema,
    ) => {
      if (egenregistrering[key] !== undefined) {
        data[key] = { [key]: egenregistrering[key] }
      }
    }

    Object.keys(egenregistrering).forEach(key => {
      addPropertyIfDefined(data, egenregistrering, key as keyof EgenregistreringSchema)
    })

    const res = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ bruksenhetId: dto.bruksenhetsId, ...data }),
    })

    if (!res.ok) {
      const body = await res.json()
      throw new ApiError(body)
    }

    return res.text()
  },

  hentEgenregistrering: async (bygningId: string) => {
    const url = `${apiRoute}/egenregistrering/${bygningId}`
    const res = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })

    if (!res.ok) {
      const body = await res.json()
      throw new ApiError(body)
    }

    const egenregistrering: EgenregistreringBygning = await res.json()

    return egenregistrering
  },
}
