import { useTranslation } from "react-i18next"
import { Vannforsyningstype, enumToOptions } from "../types/Enums"
import HjelpetekstForFelt from "./HjelpetekstForFelt"
import { InvalidInputAlert } from "./InvalidInputAlert"
import VannforsyningOgAvløpRadioGroup from "./VannforsyningOgAvløpRadioGroup"

const RegistrerVannforsyning = () => {
  const { t } = useTranslation("egenregistrering")

  return (
    <>
      <HjelpetekstForFelt>{t("hjelpetekst_vannforsyning")}</HjelpetekstForFelt>
      <VannforsyningOgAvløpRadioGroup
        name="vannforsyning"
        options={enumToOptions(Vannforsyningstype)}
        felt={{
          key: "vannforsyning",
          label: "vannforsyning",
          icon: "water_drop",
        }}
      />
      <InvalidInputAlert felt="vannforsyning" />
    </>
  )
}

export default RegistrerVannforsyning
