import { z } from "zod"
import {
  AvlopKeysEnum,
  EnergikildeKeysEnum,
  OppvarmingKeysEnum,
  VannforsyningKeysEnum,
} from "./Enums"

export type IkonForFelt =
  | "calendar_today"
  | "square_foot"
  | "lightbulb"
  | "mode_heat"
  | "water_drop"
  | "valve"

export type Felter = keyof EgenregistreringSchema

export type FelterLabels =
  | "byggeår"
  | "totalt bruksareal"
  | "energikilder"
  | "vannforsyning"
  | "oppvarmingstyper"
  | "avløp"
  | ""

const preprocessOptionalToUndefined = <T extends z.ZodTypeAny>(schema: T) =>
  z.preprocess(input => {
    if (
      input === "" ||
      Number.isNaN(input) ||
      input === false ||
      (Array.isArray(input) && input.length === 0)
    ) {
      return undefined
    }
    return input
  }, schema)

const validateByggeaar = z
  .number({
    required_error: "Vennligst oppgi et gyldig byggeår",
  })
  .nonnegative("Byggeår må være et gyldig årstall")
  .min(1000, "Byggeår må være et gyldig årstall")
  .max(new Date().getFullYear(), "Byggeår kan ikke være frem i tid")

const validateBruksareal = z
  .number()
  .nonnegative("Bruksareal kan ikke være negativt")
  .min(1, { message: "Bruksareal må være større enn 0" })

export const egenregistreringSchema = z
  .object({
    byggeaar: preprocessOptionalToUndefined(validateByggeaar.nullish()),
    bruksareal: preprocessOptionalToUndefined(validateBruksareal.nullish()),
    oppvarminger: preprocessOptionalToUndefined(
      z.enum(OppvarmingKeysEnum).array().nullish(),
    ).nullable(),
    energikilder: preprocessOptionalToUndefined(
      z.enum(EnergikildeKeysEnum).array().nullish(),
    ).nullable(),
    vannforsyning: preprocessOptionalToUndefined(z.enum(VannforsyningKeysEnum).nullish()),
    avlop: preprocessOptionalToUndefined(z.enum(AvlopKeysEnum).nullish()),
  })
  .partial()
  .superRefine((data, ctx) => {
    if (Object.values(data).every(val => val === undefined)) {
      Object.keys(data).forEach(key => {
        ctx.addIssue({
          path: [key],
          message: "Du må fylle ut minst ett felt",
          code: z.ZodIssueCode.custom,
        })
      })
    }
  })
export type EgenregistreringSchema = z.infer<typeof egenregistreringSchema>
