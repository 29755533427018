import { Box, Flex, Input, InputGroup, InputRightAddon, Spinner } from "@kvib/react"
import { useEffect, useState } from "react"
import { useFormContext } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import { Brødtekst } from "../../../core/text/components/Brødtekst"
import {
  etasjeplankodeMapping,
  sorterEtterPlankodeDeretterEtasjeummer,
} from "../../detaljert-eiendom/bygningsdetaljer/utils/etasjeUtil"
import useCurrentEiendom from "../../detaljert-eiendom/hooks/useCurrentEiendom"
import { EgenregistreringSchema } from "../types/schema"
import HjelpetekstForFelt from "./HjelpetekstForFelt"
import InputfeltTall from "./InputfeltTall"
import { InvalidInputAlert } from "./InvalidInputAlert"

const RegistrerBruksareal = () => {
  const { bygningId } = useParams()
  const { eiendom } = useCurrentEiendom()
  const { setValue } = useFormContext<EgenregistreringSchema>()
  const { t } = useTranslation("egenregistrering")

  const [areaValues, setAreaValues] = useState<{ [etasjenummer in string]: number }>({})

  useEffect(() => {
    const totalArea = Object.values(areaValues).reduce((acc, curr) => acc + curr, 0)
    setValue("bruksareal", totalArea)
  }, [areaValues, setValue])

  if (!eiendom) {
    return (
      <Flex flexDir="column" alignItems="center" justifyContent="center" gap={2}>
        <Spinner />
        <Brødtekst>Vent mens vi henter etasjene dine</Brødtekst>
      </Flex>
    )
  }

  const etasjer = eiendom.bygningerPåEiendom.find(b => b.byggId === bygningId)?.etasjer

  const handleAreaChange = ({
    etasjenummer,
    etasjeplankode,
    areal,
  }: {
    etasjenummer: string
    etasjeplankode: string
    areal: string
  }) => {
    setAreaValues(prev => ({
      ...prev,
      [etasjenummer + etasjeplankode]: Number(areal),
    }))
  }

  return (
    <>
      <HjelpetekstForFelt>{t("hjelpetekst_bruksareal")}</HjelpetekstForFelt>
      <Box mb={8}>
        {sorterEtterPlankodeDeretterEtasjeummer(etasjer!).map(etasje => (
          <Box key={etasje.etasjenummer + etasje.etasjeplankode}>
            <Flex m={2} gap={2} alignItems="center" whiteSpace="nowrap">
              <Box width="170px">{`${etasje.etasjenummer}. ${etasjeplankodeMapping[etasje.etasjeplankode]}`}</Box>
              <InputGroup>
                <Input
                  type="number"
                  onChange={e =>
                    handleAreaChange({
                      etasjenummer: etasje.etasjenummer,
                      etasjeplankode: etasje.etasjeplankode,
                      areal: e.target.value,
                    })
                  }
                />
                <InputRightAddon>m²</InputRightAddon>
              </InputGroup>
            </Flex>
          </Box>
        ))}
      </Box>
      <InputfeltTall
        variant={etasjer?.length === 0 ? "outline" : "flushed"}
        isDisabled={etasjer?.length !== 0}
        name="bruksareal"
        placeholder="0"
        erBruksarealFelt
      />
      <InvalidInputAlert felt="bruksareal" />
    </>
  )
}

export default RegistrerBruksareal
