import { Stack } from "@kvib/react"
import { BygningskortMedKartutsnitt } from "../../../../kartutsnitt/components/BygningskortMedKartutsnitt"
import useCurrentEiendom from "../../hooks/useCurrentEiendom"
import { bygningAria, sorterPåBygningstype } from "../utils/bygningerUtils"

const AlleBygningskortMedKartutsnittStack = () => {
  const { eiendom } = useCurrentEiendom()

  if (!eiendom) return null

  return (
    <Stack
      data-cy="bygningskort-liste"
      w="100%"
      justifyContent="center"
      gap="14px"
      mt={{
        base: "12px",
        lg: "24px",
      }}
      aria-label={bygningAria(eiendom.bygningerPåEiendom)}
    >
      {sorterPåBygningstype(eiendom.bygningerPåEiendom).map((bygning, index) => {
        return <BygningskortMedKartutsnitt key={bygning.byggId} bygning={bygning} index={index} />
      })}
    </Stack>
  )
}

export default AlleBygningskortMedKartutsnittStack
