import { Center, Flex, Image, ImageProps } from "@kvib/react"
import { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import eiendomPlaceholder from "../../../assets/eiendom.svg"
import { Brødtekst } from "../../core/text/components/Brødtekst"
import { DetaljertEiendom } from "../../eiendom/detaljert-eiendom/types/DetaljertEiendom"
import { Eiendom } from "../../eiendom/eiendomsoversikt/types/Eiendom"
import { formaterAdresseForEiendom } from "../../eiendom/eiendomsoversikt/utils/adresseForEiendomUtils"
import { useKartutsnitt } from "../api/useKartutsnitt"
import useTeigpolygon from "../api/useTeigpolygon"
import ImageWithSkeleton from "./ImageWithSkeleton"

interface Props extends ImageProps {
  eiendom?: Eiendom | DetaljertEiendom
  queryKeyId?: string
  visTeigpolygon?: boolean
  feilmelding?: string[]
  onLoadComplete?: (status: { isLoaded: boolean; isMapMissing: boolean }) => void
}

const FellesKartutsnittkomponent = ({
  eiendom,
  queryKeyId,
  visTeigpolygon,
  onLoadComplete,
  feilmelding,
  ...props
}: Props) => {
  const [imgWidth, setImgWidth] = useState<number | null>(null)
  const [imgHeight, setImgHeight] = useState<number | null>(null)
  const imageRef = useRef<HTMLImageElement>(null)
  const { t } = useTranslation(["nøkkelbegreper", "eiendomskort"])

  const {
    data: kartutsnitt,
    isSuccess,
    isFetched,
  } = useKartutsnitt(imgWidth, imgHeight, queryKeyId, eiendom?.matrikkelnummer)

  const { data: teigpolygoner } = useTeigpolygon(
    visTeigpolygon ? imgWidth : null,
    visTeigpolygon ? imgHeight : null,
    queryKeyId,
    eiendom?.matrikkelnummer,
  )
  const teigpolygon = teigpolygoner && teigpolygoner[0]

  const imageUrl = kartutsnitt ? `data:image/png;base64,${kartutsnitt}` : eiendomPlaceholder
  const brukerPlaceholder = imageUrl === eiendomPlaceholder

  // Alt-tekst blir matrikkelnummer dersom eiendommen ikke har adresse
  const adresse =
    eiendom &&
    formaterAdresseForEiendom(
      eiendom.adresser,
      eiendom.matrikkelnummer,
      eiendom.matrikkelenhetstype,
    )

  const altAdresse =
    adresse === "Eiendommen har ingen adresse"
      ? t("med_matrikkelnummer", {
          ns: "nøkkelbegreper",
          matrikkelnummer: eiendom?.matrikkelnummer,
        })
      : adresse
  const imageAlt = kartutsnitt
    ? t("kartutsnitt_eiendom_alt_tittel_med_adresse", { ns: "eiendomskort", adresse: altAdresse })
    : t("mangler_kartutsnitt_eiendom_alt_tittel_med_adresse", {
        ns: "eiendomskort",
        adresse: altAdresse,
      })

  useEffect(() => {
    if (onLoadComplete) {
      onLoadComplete({
        isLoaded: isSuccess,
        isMapMissing: isFetched && !kartutsnitt,
      })
    }
  }, [isSuccess, isFetched, kartutsnitt, onLoadComplete])

  const kanViseTeigpolygon = visTeigpolygon && teigpolygon && imgWidth && imgHeight && isSuccess

  const imageRatio = imgWidth && imgHeight ? imgWidth / imgHeight : 1

  if (isFetched && brukerPlaceholder) {
    return (
      <Flex
        bg="green.50"
        position="relative"
        h="100%"
        pt="56.25%"
        overflow="hidden"
        objectFit="cover"
        alignItems="flex-end"
        pr="5%"
        pl="2%"
        transform={props.transform}
        filter={props.filter}
      >
        {feilmelding && (
          <Center
            pos="absolute"
            top={0}
            left={0}
            right={0}
            bottom={0}
            zIndex={1}
            flexDir="column"
            p="0.5rem"
          >
            {feilmelding.map(f => (
              <Brødtekst>{f}</Brødtekst>
            ))}
          </Center>
        )}
        <Image h="75%" position="absolute" src={eiendomPlaceholder} alt={imageAlt} opacity=".1" />
      </Flex>
    )
  }

  return (
    <ImageWithSkeleton
      src={imageUrl}
      isLoaded={isFetched}
      alt={imageAlt}
      onLoad={() => {
        if (imageRef.current) {
          setImgWidth(imageRef.current.offsetWidth)
          setImgHeight(imageRef.current.offsetHeight)
        }
      }}
      ref={imageRef}
      {...props}
    >
      {kanViseTeigpolygon && (
        <svg
          viewBox={`0 0 ${500 * imageRatio} 500`}
          width="100%"
          height="100%"
          preserveAspectRatio="xMidYMid slice"
        >
          <polygon
            points={teigpolygon
              .map(({ x, y }) => `${x * 5 * imageRatio}, ${500 - y * 5}`)
              .join(" ")}
            fill="rgba(89, 82, 210, 0.4)"
            stroke="rgba(89, 82, 210, 0.9)"
            strokeWidth="2"
          />
        </svg>
      )}
    </ImageWithSkeleton>
  )
}

export default FellesKartutsnittkomponent
