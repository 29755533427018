import useCurrentBygning from "../../detaljert-eiendom/hooks/useCurrentBygning"
import { useEgenregistreringIsEnabled } from "../api/useEgenregistreringIsEnabled"
import { harNøyaktigEnBruksenhetIBygning } from "../utils"

const useBygningKanEgenregistreresPå = (bygningId: string | undefined) => {
  const { currentBygning, bruksenhetIds } = useCurrentBygning(bygningId)
  const { data: egenregistreringIsEnabled } = useEgenregistreringIsEnabled()

  if (!currentBygning || !bygningId || !bruksenhetIds) return false

  const nøyaktigEnBruksenhetIBygning = harNøyaktigEnBruksenhetIBygning(bruksenhetIds)

  return egenregistreringIsEnabled && nøyaktigEnBruksenhetIBygning
}

export default useBygningKanEgenregistreresPå
