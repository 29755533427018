import {
  Alert,
  AlertDescription,
  AlertIcon,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
} from "@kvib/react"
import { useFormContext } from "react-hook-form"
import { EgenregistreringSchema } from "../types/schema"
import { useSkalViseTomtSkjemaFeilmelding } from "../utils"
import RegistrerAvlop from "./RegistrerAvløp"
import RegistrerBruksareal from "./RegistrerBruksareal"
import RegistrerByggeår from "./RegistrerByggeår"
import RegistrerEnergikilder from "./RegistrerEnergikilder"
import RegistrerOppvarmingstyper from "./RegistrerOppvarmingstyper"
import RegistrerVannforsyning from "./RegistrerVannforsyning"

interface EgenregistreringModalForAlleFelterProps {
  isOpen: boolean
  onClose: () => void
  onSubmit: (data: EgenregistreringSchema) => void
  isLoading: boolean
}

export const EgenregistreringModalForAlleFelter = ({
  isOpen,
  onClose,
  onSubmit,
  isLoading,
}: EgenregistreringModalForAlleFelterProps) => {
  const formContext = useFormContext<EgenregistreringSchema>()

  const handleSubmit = () => {
    formContext.handleSubmit(onSubmit)()
  }
  const skalViseTomtSkjemaFeilmelding = useSkalViseTomtSkjemaFeilmelding()

  return (
    <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Egenregistrering for bygning</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack spacing={6}>
            <RegistrerByggeår />
            <RegistrerBruksareal />
            <RegistrerEnergikilder />
            <RegistrerOppvarmingstyper />
            <RegistrerVannforsyning />
            <RegistrerAvlop />
          </Stack>
          {skalViseTomtSkjemaFeilmelding && (
            <Alert mt="24px" status="error" variant="top-accent">
              <AlertIcon />
              <AlertDescription>Vennligst fyll inn minst én gyldig verdi</AlertDescription>
            </Alert>
          )}
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={onClose}>
            Avbryt
          </Button>
          <Button onClick={handleSubmit} isLoading={isLoading}>
            Send inn
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
