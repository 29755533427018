import { Flex } from "@kvib/react"
import { formaterStorForbokstav } from "../../../../utils/formateringUtils"
import { Overskrift } from "../../../core/text/components/Overskrift"
import { Felter, FelterLabels, IkonForFelt } from "../types/schema"
import CircularIcon from "./CircularIcon"

export const IkonOgFeltLabel = ({
  felt,
}: {
  felt: { key: Felter; label: FelterLabels; icon: IkonForFelt }
}) => {
  return (
    <Flex alignItems="center">
      <CircularIcon icon={felt.icon} />
      <Overskrift variant="h4">{formaterStorForbokstav(felt.label)}</Overskrift>
    </Flex>
  )
}
