import { useQueryClient } from "@tanstack/react-query"
import { usePostHog } from "posthog-js/react"
import { useEffect } from "react"
import { useParams } from "react-router-dom"
import Section from "../../../core/components/layout/Section"
import { finnerIngenPathsSomMatcher } from "../../../core/error-handling/types/finnerIngenPathSomPatcher"
import { api } from "../../../shared-api/router"
import { useEgenregistreringIsEnabled } from "../../registrering/api/useEgenregistreringIsEnabled"
import useCurrentEiendom from "../hooks/useCurrentEiendom"
import Bygningsdetaljer from "./Bygningsdetaljer"
import Eiendomsdetaljer from "./Eiendomsdetaljer"

import FlereOpplysninger from "./FlereOpplysninger"

const DetaljertEiendom = () => {
  const { eiendom, isEiendommerPending, finnerIkkeEiendom } = useCurrentEiendom()
  const queryClient = useQueryClient()
  const posthog = usePostHog()
  const { eiendomsIndex } = useParams()

  const harBygningerPåEiendom =
    eiendom && eiendom.bygningerPåEiendom && eiendom.bygningerPåEiendom.length > 0

  const commonPaddingForSection = ["30px", "40px"]

  if (!isEiendommerPending && finnerIkkeEiendom) {
    throw finnerIngenPathsSomMatcher
  }

  const { data: kanEgenregistrere } = useEgenregistreringIsEnabled()

  useEffect(() => {
    if (!kanEgenregistrere || !eiendom?.bygningerPåEiendom) {
      return
    }

    eiendom.bygningerPåEiendom.forEach(bygning => {
      queryClient.prefetchQuery({
        queryKey: ["egenregistrering", bygning.byggId],
        queryFn: () => api.egenregistrering.hentEgenregistrering(bygning.byggId),
      })
    })
  }, [kanEgenregistrere, eiendom?.bygningerPåEiendom, queryClient])

  if (!localStorage.getItem(`${eiendomsIndex}`)) {
    posthog.capture("Antall bygninger", {
      antall_bygninger: eiendom?.bygningerPåEiendom.length,
    })
    localStorage.setItem(`${eiendomsIndex}`, "true")
  }

  return (
    <>
      <Section pt="12px" pb={commonPaddingForSection}>
        <Eiendomsdetaljer eiendom={eiendom} />
      </Section>
      {harBygningerPåEiendom && (
        <Section bg="green.50" pt={commonPaddingForSection} pb={commonPaddingForSection}>
          <Bygningsdetaljer />
        </Section>
      )}
      <Section pt={commonPaddingForSection} pb={commonPaddingForSection} bg="white">
        <FlereOpplysninger />
      </Section>
    </>
  )
}

export default DetaljertEiendom
