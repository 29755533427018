import { zodResolver } from "@hookform/resolvers/zod"
import { Divider, Table, TableContainer, Tbody, Td, Text, Tr, useDisclosure } from "@kvib/react"
import { useQueryClient } from "@tanstack/react-query"
import { useState } from "react"
import { FormProvider, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { formaterStorForbokstav } from "../../../utils/formateringUtils"
import Section from "../../core/components/layout/Section"
import { Overskrift } from "../../core/text/components/Overskrift"
import BygningstypeOgVegadresseOverskrift from "../detaljert-eiendom/bygningsdetaljer/components/BygningstypeOgAdresseOverskrift"
import { getVegadresserPåBygning } from "../detaljert-eiendom/bygningsdetaljer/utils/bygningerUtils"
import useCurrentBygning from "../detaljert-eiendom/hooks/useCurrentBygning"
import useCurrentEiendom from "../detaljert-eiendom/hooks/useCurrentEiendom"
import useOpprettEgenregistrering from "./api/useOpprettEgenregistering"
import { EgenregistreringKnapp } from "./components/EgenregistreringKnapp"
import EgenregistreringModal from "./components/EgenregistreringModal"
import { EgenregistreringModalForAlleFelter } from "./components/EgenregistreringModalForAlleFelter"
import FjernVerdiFraEgenregistrering from "./components/FjernVerdiFraEgenregistrering"
import { IkonOgFeltLabel } from "./components/IkonOgFeltLabel"
import Registreringstidspunkt from "./components/Registreringstidspunkt"
import useErTomVerdi from "./hooks/useErTomVerdi"
import { EgenregistreringBygning } from "./types/Egenregistrering"
import {
  EgenregistreringSchema,
  Felter,
  FelterLabels,
  IkonForFelt,
  egenregistreringSchema,
} from "./types/schema"
import { felter, ikkeRegistrertVerdi, visFeltverdi } from "./utils"

type ModalType = "ettFelt" | "alleFelter"

const EgenregistreringForm = ({
  egenregistreringForBygning,
}: {
  egenregistreringForBygning: EgenregistreringBygning
}) => {
  const isTomVerdi = useErTomVerdi()
  const { currentBygning, bruksenhetIds } = useCurrentBygning()
  const { eiendom } = useCurrentEiendom()
  const { t } = useTranslation("egenregistrering")

  const bruksenhetId = egenregistreringForBygning.bruksenheter[0].bruksenhetId
  const { mutate: sendEgenregistrering, isPending } = useOpprettEgenregistrering()
  const queryClient = useQueryClient()

  const [inputField, setInputField] = useState<
    | {
        key: Felter
        label: FelterLabels
        icon: IkonForFelt
      }
    | undefined
  >(undefined)
  const [modalType, setModalType] = useState<ModalType | undefined>(undefined)
  const { isOpen, onOpen, onClose } = useDisclosure()

  const form = useForm<EgenregistreringSchema>({
    resolver: zodResolver(egenregistreringSchema),
    reValidateMode: "onChange",
  })

  if (!currentBygning || !eiendom || !bruksenhetIds) return null

  const handleOpenModal = (
    type: ModalType,
    felt?: {
      key: Felter
      label: FelterLabels
      icon: IkonForFelt
    },
  ) => {
    setModalType(type)
    if (type === "ettFelt" && felt) {
      setInputField(felt)
    }
    onOpen()
  }

  const handleCloseModal = () => {
    setInputField(undefined)
    setModalType(undefined)
    form.reset()
    onClose()
  }

  const handleSubmit = (oppdatertEgenregistrering: EgenregistreringSchema) => {
    sendEgenregistrering(
      {
        bygningId: egenregistreringForBygning.bygningId,
        bruksenhetsId: bruksenhetId.toString(),
        egenregistrering: oppdatertEgenregistrering,
      },
      {
        onSuccess: () => {
          handleCloseModal()
          queryClient.invalidateQueries({ queryKey: ["egenregistrering"] })
        },
      },
    )
  }

  const tekstForEgenregistreringsknapp = (felt: {
    key: Felter
    label: FelterLabels
    icon: IkonForFelt
  }): string => {
    if (visFeltverdi(felt.key, egenregistreringForBygning) === ikkeRegistrertVerdi) {
      return formaterStorForbokstav(t("tekst_registrer_verdi_knapp"))
    }

    return formaterStorForbokstav(t("tekst_endre_verdi_knapp"))
  }

  const vegadresseForBygning = getVegadresserPåBygning(eiendom.adresser.vegadresser, bruksenhetIds)

  return (
    <Section mb="24px">
      <Overskrift mb="50px" variant="h1">
        {t("egenregistrering_sidetittel")}
      </Overskrift>
      <FormProvider {...form}>
        <TableContainer>
          <form
            onSubmit={form.handleSubmit(
              handleSubmit,
              //handleSubmit blir bare kalt dersom valideringen i formet er vellykket.
              //Dersom man har glemt å vise erroren i UIet, vil ingenting tilsynelatende skje når du submitter.
              // onInvalid-callbacket til form.handleSubmit() håndterer dette tilfellet, og er en debug-hjelp til utvikler når mans gjør store strukturelle endringer i formet.
              err => {
                throw err
              },
            )}
          >
            <BygningstypeOgVegadresseOverskrift
              bygningstype={currentBygning.bygningstype}
              erSeksjon={eiendom.matrikkelenhetstype === "Eierseksjon"}
              vegadresse={vegadresseForBygning}
            />
            <Divider mt="16px" />
            <Table colorScheme="green">
              <Tbody>
                {felter.map(felt => (
                  <Tr key={felt.key}>
                    <Td>
                      <IkonOgFeltLabel felt={felt} />
                    </Td>
                    <Td maxW="350px">
                      <Text width="100%" whiteSpace="normal">
                        {visFeltverdi(felt.key, egenregistreringForBygning)}
                      </Text>
                    </Td>
                    <Td>
                      {visFeltverdi(felt.key, egenregistreringForBygning) !==
                        ikkeRegistrertVerdi && <Registreringstidspunkt feltKey={felt.key} />}
                    </Td>
                    <Td textAlign="left">
                      <EgenregistreringKnapp
                        icon={isTomVerdi(felt.key) ? "add" : "edit"}
                        onClick={() => handleOpenModal("ettFelt", felt)}
                      >
                        {tekstForEgenregistreringsknapp(felt)}
                      </EgenregistreringKnapp>
                      <FjernVerdiFraEgenregistrering felt={felt} handleSubmit={handleSubmit} />
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </form>
        </TableContainer>
        {modalType === "ettFelt" && inputField && (
          <EgenregistreringModal
            felt={inputField}
            isOpen={isOpen}
            onClose={handleCloseModal}
            onSubmit={handleSubmit}
            isLoading={isPending}
          />
        )}
        {modalType === "alleFelter" && (
          <EgenregistreringModalForAlleFelter
            isOpen={isOpen}
            onClose={handleCloseModal}
            onSubmit={handleSubmit}
            isLoading={isPending}
          />
        )}
      </FormProvider>
    </Section>
  )
}

export default EgenregistreringForm
