import { t } from "i18next"
import { Overskrift } from "../../../core/text/components/Overskrift"
import AlleBygningskortMedKartutsnittStack from "../bygningsdetaljer/components/AlleBygningskortMedKartutsnittStack"

const Bygningsdetaljer = () => {
  return (
    <>
      <Overskrift variant="h2">
        {t("bygningsdetaljer_overskrift", { ns: "bygningskort" })}
      </Overskrift>
      <AlleBygningskortMedKartutsnittStack />
    </>
  )
}

export default Bygningsdetaljer
