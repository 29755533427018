import { useTranslation } from "react-i18next"
import { Avlopstype, enumToOptions } from "../types/Enums"
import HjelpetekstForFelt from "./HjelpetekstForFelt"
import { InvalidInputAlert } from "./InvalidInputAlert"
import VannforsyningOgAvløpRadioGroup from "./VannforsyningOgAvløpRadioGroup"

const RegistrerAvlop = () => {
  const { t } = useTranslation("egenregistrering")

  return (
    <>
      <HjelpetekstForFelt>{t("hjelpetekst_avløp")}</HjelpetekstForFelt>
      <VannforsyningOgAvløpRadioGroup
        name="avlop"
        options={enumToOptions(Avlopstype)}
        felt={{
          key: "avlop",
          label: "avløp",
          icon: "valve",
        }}
      />
      <InvalidInputAlert felt="avlop" />
    </>
  )
}

export default RegistrerAvlop
