import { useFormContext } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { Oppvarmingstype, enumToOptions } from "../types/Enums"
import Flervalg from "./Flervalg"
import HjelpetekstForFelt from "./HjelpetekstForFelt"
import { InvalidInputAlert } from "./InvalidInputAlert"

const RegistrerOppvarmingstyper = () => {
  const formContext = useFormContext()
  const { t } = useTranslation("egenregistrering")

  return (
    <>
      <HjelpetekstForFelt>{t("hjelpetekst_oppvarmingstyper")}</HjelpetekstForFelt>
      <Flervalg
        name="oppvarminger"
        options={enumToOptions(Oppvarmingstype)}
        register={formContext.register}
        felt={{
          key: "oppvarminger",
          label: "oppvarmingstyper",
          icon: "mode_heat",
        }}
      />
      <InvalidInputAlert felt="oppvarminger" />
    </>
  )
}

export default RegistrerOppvarmingstyper
