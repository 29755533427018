const keys = {
  "nb-NO": {
    tab_tittel: "Personvern og sikkerhet",
    overskrift: "Personvern og sikkerhet på mineiendom.kartverket.no",
    personopplysninger: {
      tittel: "Personopplysninger",
      avsnitt: [
        "Vi behandler ikke personopplysninger om deg før du logger inn på Min eiendom.",
        "Når du logger inn på løsningen Min eiendom henter vi utvalgte opplysninger fra Eiendomsregisteret (Matrikkelen) om eiendommer du er registrert som eier eller fester av. Disse opplysningene kan knyttes til deg som bruker og er derfor å anses som personopplysninger.",
        "Personopplysningene dine blir behandlet etter kravene i personopplysningsloven. Opplysningene blir ikke lagret etter at sesjonen din er avsluttet. Du kan avslutte sesjonen ved å logge ut manuelt, ellers skjer automatisk utlogging etter 30 minutter.",
      ],
    },

    mer_om_personvernerklæring_link: "Les mer om Kartverkets personvernerklæring",
    innlogging: {
      tittel: "Innlogging",
      avsnitt: [
        "Mineiendom.kartverket.no krever innlogging via ID-porten (MinID, BankID osv.). Når du logger deg inn, er det erklæringen om sikkerhet og informasjonskapsler på ID-porten som gjelder.",
      ],
    },
    mer_om_IDporten_innlogging_link: "Les mer om sikkerhet og personvern for ID-porten",
  },
  "nn-NO": {
    tab_tittel: "Personvern og tryggleik",
    overskrift: "Personvern og tryggleik på mineiendom.kartverket.no",
    personopplysninger: {
      tittel: "Personopplysningar",
      avsnitt: [
        "Vi behandlar ikkje personopplysningar om deg før du loggar inn på Min eiendom.",
        "Når du loggar inn på løysinga Min eiendom hentar vi utvalde opplysningar frå Eigedomsregisteret (Matrikkelen) om eigedommar du er registrert som eigar eller festar av. Desse opplysningane kan knytast til deg som brukar og er difor å sjå på som personopplysningar.",
        "Personopplysningane dine blir behandla etter krava i personopplysningsloven. Opplysningane blir ikkje lagra etter at sesjonen din er avslutta. Du kan avslutte sesjonen ved å logge ut manuelt, elles skjer automatisk utlogging etter 30 minuttar.",
      ],
    },
    mer_om_personvernerklæring_link: "Les meir om Kartverkets personvernerklæring",
    innlogging: {
      tittel: "Innlogging",
      avsnitt: [
        "Mineiendom.kartverket.no krev innlogging via ID-porten (MinID, BankID osv.). Når du loggar deg inn, er det erklæringa om tryggleik og informasjonskapslar på ID-porten som gjeld.",
      ],
    },
    mer_om_IDporten_innlogging_link: "Les meir om tryggleik og personvern for ID-porten",
  },
}

export const personvernOgSikkerhet = {
  "nb-NO": keys["nb-NO"],
  "nn-NO": keys["nn-NO"],
}
