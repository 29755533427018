import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
} from "@kvib/react"
import { Brødtekst } from "../../core/text/components/Brødtekst"

import { usePostHog } from "posthog-js/react"
import { useState } from "react"
import { Trans } from "react-i18next"
import { useLocation } from "react-router-dom"
import { EksternLenke } from "../../core/text/components/EksternLenke"
import { TittelAvsnitt } from "../../localization/translations"

interface ForklaringAccordionProps<T> {
  translations: T[] | string
}

const ForklaringAccordion = <
  T extends {
    tittel: string
    avsnitt: string[]
    lenker?: { lenke: string; tekst: string }[]
    statuser?: TittelAvsnitt[]
    matrikkelenhetstyper?: TittelAvsnitt[]
  },
>(
  props: ForklaringAccordionProps<T>,
) => {
  const { translations: datafelter_accordion } = props

  const { hash } = useLocation()
  const posthog = usePostHog()

  const [spørsmålIndex, setSpørsmålIndex] = useState<number | undefined>(
    hash ? Number(hash.replace("#accordion-button-", "")) : undefined,
  )

  return (
    <Accordion
      allowToggle
      borderTopColor="green.500"
      borderBottomColor="green.500"
      index={spørsmålIndex}
      onClick={() => setSpørsmålIndex(undefined)}
    >
      {Array.isArray(datafelter_accordion) &&
        datafelter_accordion.map((datafelt, index) => (
          <AccordionItem key={datafelt.tittel} id={index.toString()}>
            <AccordionButton textColor="green.500" onClick={() => posthog.capture(datafelt.tittel)}>
              <Box flex="1" textAlign="left">
                {datafelt.tittel}
              </Box>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel>
              {datafelt.avsnitt.map(avsnitt => (
                <Trans key={avsnitt}>
                  <Brødtekst>{avsnitt}</Brødtekst>
                  <br />
                </Trans>
              ))}

              {datafelt.lenker?.map(lenke => (
                <>
                  <EksternLenke m="10px" tilAdresse={lenke.lenke}>
                    {lenke.tekst}
                  </EksternLenke>
                  <br />
                </>
              ))}
              <Accordion allowToggle bg="white" mt="12px">
                {datafelt.statuser?.map(status => (
                  <AccordionItem key={status.tittel}>
                    <AccordionButton>
                      <Box flex="1" textAlign="left">
                        {status.tittel}
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                    <AccordionPanel>
                      <Brødtekst>{status.avsnitt}</Brødtekst>
                    </AccordionPanel>
                  </AccordionItem>
                ))}
              </Accordion>

              <Accordion allowToggle bg="white" mt="12px">
                {datafelt.matrikkelenhetstyper?.map(matrikkelenhetstype => (
                  <AccordionItem key={matrikkelenhetstype.tittel}>
                    <AccordionButton
                      onClick={() => {
                        posthog.capture(datafelt.tittel, {
                          matrikkelenhetstype: matrikkelenhetstype.tittel,
                        })
                      }}
                    >
                      <Box flex="1" textAlign="left">
                        {matrikkelenhetstype.tittel}
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                    <AccordionPanel>
                      <Brødtekst>{matrikkelenhetstype.avsnitt}</Brødtekst>
                    </AccordionPanel>
                  </AccordionItem>
                ))}
              </Accordion>
            </AccordionPanel>
          </AccordionItem>
        ))}
    </Accordion>
  )
}

export default ForklaringAccordion
