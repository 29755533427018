import { Box, Button, Image, LinkOverlay } from "@kvib/react"
import { usePostHog } from "posthog-js/react"
import { useLayoutEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import pin from "../../../assets/pin.svg"
import { useScreenView } from "../../core/hooks/useScreenview"
import { Koordinat } from "../../eiendom/detaljert-eiendom/bygningsdetaljer/types/Bygning"
import { getBygningskoordinat } from "../../eiendom/detaljert-eiendom/bygningsdetaljer/utils/bygningerUtils"
import useCurrentBygning from "../../eiendom/detaljert-eiendom/hooks/useCurrentBygning"
import useCurrentEiendom from "../../eiendom/detaljert-eiendom/hooks/useCurrentEiendom"
import useBygningskoordinater from "../api/useBygningskoordinater"
import FellesKartutsnittkomponent from "./FellesKartutsnittkomponent"

const KartutsnittMedBygningskoordinat = ({ bygningId }: { bygningId: string }) => {
  const { currentBygning } = useCurrentBygning(bygningId)
  const { eiendom } = useCurrentEiendom()
  const posthog = usePostHog()
  const { isMobile, isDesktop } = useScreenView()
  const { t } = useTranslation("bygningskort")

  const getKartutsnittLenke = (bygningsKoordinat: Koordinat): string =>
    `#!?project=seeiendom&zoom=16&lat=${bygningsKoordinat.lat}&lon=${bygningsKoordinat.long}&markerLat=${bygningsKoordinat.lat}&markerLon=${bygningsKoordinat.long}&panel=Seeiendom&showSelection=true`

  const [harKartutsnitt, setHarKartutsnitt] = useState(false)
  const [imgWidth, setImgWidth] = useState<number | null>(null)
  const [imgHeight, setImgHeight] = useState<number | null>(null)

  const imgRef = useRef<HTMLImageElement>(null)
  const { data: koordinater } = useBygningskoordinater(
    imgWidth,
    imgHeight,
    eiendom?.matrikkelnummer,
    eiendom?.bygningerPåEiendom,
  )

  useLayoutEffect(() => {
    if (imgRef.current) {
      const width = imgRef.current.clientWidth
      const height = imgRef.current.clientHeight
      setImgWidth(width)
      setImgHeight(height)
    }
  }, [])

  if (!currentBygning || !eiendom) return null

  const bygningskoordinat = getBygningskoordinat(koordinater, currentBygning)

  return (
    <Box
      pos="relative"
      overflow="hidden"
      {...(!isMobile && {
        borderRadius: "var(--kvib-radii-md)",
        boxShadow: "var(--kvib-shadows-base)",
      })}
      aspectRatio="4/3"
      ref={imgRef}
    >
      <FellesKartutsnittkomponent
        eiendom={eiendom}
        queryKeyId="bygning"
        onLoadComplete={({ isLoaded, isMapMissing }) => {
          if (isLoaded && !isMapMissing) {
            setHarKartutsnitt(true)
          }
        }}
        feilmelding={t("feilmelding_ved_manglende_kartutsnitt", { returnObjects: true })}
        {...(!harKartutsnitt && { transform: "scaleX(-1)", filter: "grayscale(100%)" })} // dersom placeholder vises skal denne speilvendes og gråtoner legges på
      />
      {harKartutsnitt && bygningskoordinat?.representasjonspunkt && (
        <Box
          pos="absolute"
          left={`${bygningskoordinat.representasjonspunkt.long}%`}
          top={`${100 - bygningskoordinat.representasjonspunkt.lat}%`}
          transform="translate(-50%, -80%)"
          zIndex="1"
        >
          <Image src={pin} aria-hidden="true" h="36px" />
        </Box>
      )}

      {currentBygning?.representasjonspunkt && (
        <LinkOverlay
          href={`https://norgeskart.no/${getKartutsnittLenke(currentBygning.representasjonspunkt)}`}
          isExternal
          tabIndex={-1}
        >
          <Button
            rightIcon="open_in_new"
            onClick={() => {
              posthog.capture("Ekstern lenke", {
                link_name: "Vis bygning i kart",
                tilAdresse: "https://norgeskart.no/{bygning}",
              })
            }}
            colorScheme="blue"
            position="absolute"
            right={2}
            bottom={2}
            iconAriaIsHidden
            size={isDesktop ? "md" : "sm"}
          >
            {t("vis_bygning_i_kart_knapp")}
          </Button>
        </LinkOverlay>
      )}
    </Box>
  )
}

export default KartutsnittMedBygningskoordinat
