import { useTranslation } from "react-i18next"
import HjelpetekstForFelt from "./HjelpetekstForFelt"
import InputfeltTall from "./InputfeltTall"
import { InvalidInputAlert } from "./InvalidInputAlert"

const RegistrerByggeår = () => {
  const { t } = useTranslation("egenregistrering")

  return (
    <>
      <HjelpetekstForFelt>{t("hjelpetekst_byggeår")}</HjelpetekstForFelt>
      <InputfeltTall name="byggeaar" placeholder="F. eks 2023" />
      <InvalidInputAlert felt="byggeaar" />
    </>
  )
}

export default RegistrerByggeår
