import { Checkbox, CheckboxGroup, FormLabel, Grid, Stack } from "@kvib/react"
import { UseFormRegister } from "react-hook-form"
import { EgenregistreringSchema, Felter, FelterLabels, IkonForFelt } from "../types/schema"
import { getModalLabel } from "../utils"

interface Props<T> {
  name: keyof EgenregistreringSchema
  register: UseFormRegister<EgenregistreringSchema>
  options: T
  felt: {
    key: Felter
    label: FelterLabels
    icon: IkonForFelt
  }
}

const Flervalg = <T extends object>({ name, register, options, felt }: Props<T>) => (
  <Stack w="fit-content">
    <FormLabel>Velg {getModalLabel(felt.key)} for bygningen</FormLabel>
    <CheckboxGroup>
      <Grid ml="12px" gap={2} columnGap={12} templateColumns="1fr 1fr">
        {Object.entries(options).map(([key, label]) => (
          <Checkbox defaultChecked={true} key={key} value={key} {...register(name)}>
            {label}
          </Checkbox>
        ))}
      </Grid>
    </CheckboxGroup>
  </Stack>
)

export default Flervalg
